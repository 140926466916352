<template>
  <div class="pin-gen-main container">
    <!-- <div class="title">
      <div class="box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <img
          src="@/assets/icons/back.svg"
          v-if="localDisplayLang === 'eng'"
          alt
          @click="initialRoute"
        />
        <img
          src="@/assets/icons/back.svg"
          class="rotate-img"
          v-else
          @click="initialRoute"
          alt
        />
        &nbsp;&nbsp;&nbsp;
        <p>{{$t('Pin Generator')}}</p>
      </div>
    </div> -->
    <div class="pin-gen-body">
      <div class="title-pin">
       {{$t('Description tv text')}}
      </div>
			<div class="title-pin-new">
       {{$t('Description tv text2')}} 
		</div>
      <div class="pin-gen-body-input">
        <!-- <input
          type="text"
          maxlength="6"
          class="lookup-form-container-input-container-inputtext"
        /> -->
        <div class="pin-gen-text">{{pinGenText}}</div>
          <Loading v-if="statusCode"></Loading>
        <button
          type="button"
          title="GENERATE PAIRING KEY"
          @click="actgenpin"
          class="button-primary login primary-net5-button key-gen-button">
          {{$t('GENERATE PAIRING KEY')}}
        </button>

        <p class="error-text" v-if="errorTxt">{{errorTxt}}</p>
        <p>{{$t('Support tv Text')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

export default {
  data() {
    return {
      localDisplayLang: null,
      pinGenText: '',
      errorTxt: '',
      statusCode: false
    };
  },
  methods: {
    initialRoute() {
      this.$router.push({ name: "Home" });
    },
    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    actgenpin () {
      let isAnonymous = localStorage.getItem("subscriberDetails") ? false : true;
      if ( isAnonymous ) {
         let payload = {
            state: true,
            formType: "lookup"
            };
            eventBus.$emit("authPopup", payload);
  
      } else {
        this.statusCode = true;
        console.log("pin generation", this.pinGenText)
          let payload = {
              devicetype: 'TV'
          }
          store.dispatch("actGetTvPin", payload).then(response => {
            this.statusCode = false;

            if (!response.data.errorcode) {
              this.errorTxt = ''
              this.pinGenText = response.data.devicepin;
            } else if (response.data.errorcode) {
              this.errorTxt = response.data.reason
            }
          }).catch(() => { 
            this.statusCode = false;
            this.errorTxt = "please try again";
          })
      }
    }
  },
  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.actgenpin = this.debounce(this.actgenpin, 500)

    eventBus.$on("check-local-storage", (response) => {
      this.localDisplayLang = response;
    });
  },
  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      )
  }
};
</script>

<style lang="scss" scoped>

@import "@/sass/_variables.scss";
@import "./PinGeneration.scss"
</style>